@import "../../variables/layout/common/ticket-information-banner";

@mixin headerInformationBanner($selector: '.ticketInformationBanner', $isHomePage: false) {
	#{$selector} {

		position: relative;
		
		@include media-breakpoint-up(lg) {
			max-width: 590px;

			@if $isHomePage {
				margin: 15px auto 0 auto;
			}

			@else {
				margin: 0 0 0 15px;
			}
		}

		@include media-breakpoint-down(lg) {
			@if $isHomePage {
				margin: 15px auto 0 auto;
			}

			@else {
				width: calc(100% - 30px);
				margin: 15px;
			}
			flex: unset;
		}

		@if $isHomePage {
			width: 80%;
			height: auto;
		}
		display: none;
		padding: 15px;
		background: $ticketInformationBanner-background-color;
		border-radius: 4px;
		box-shadow: 0px 0px 31.32px 4.68px $ticketInformationBanner-shadow-color;

		@if $isHomePage {
			border-left: 4px solid $ticketInformationBanner-border-color-homePage;
		}

		@else {
			border-left: 4px solid $ticketInformationBanner-border-color;
		}

		&.opened {
			@include media-breakpoint-down(lg) {
				z-index: 26;
			}
		}

		p {
			@include sf_pro_textsemibold;

			@if $isHomePage {
				display: inline;
				color: $ticketInformationBanner-accordion-header-color-homePage;

				@include media-breakpoint-down(lg) {
					margin-left: $ticketInformationBanner-accordion-margin-left-mobile;
					font-size: 14px;
				}
			}

			@else {
				color: $ticketInformationBanner-accordion-header-color;
			}
			margin: 0 15px 0 $ticketInformationBanner-accordion-margin-left;

			@include media-breakpoint-down(lg) {
				@if $isHomePage == false {
					margin: 0 15px 0 $ticketInformationBanner-accordion-margin-left;
				}
			}

			i {
				position: absolute;
				font-size: 20px;

				@if $isHomePage {
					top: 2px;
					left: 0;

					@include media-breakpoint-down(lg) {
						top: 4px;
						font-size: 16px;
					}
				}

				@else {
					top: 16px;
					left: 17px;
				}
			}

			.text {
				color: $default-text-color;
			}
		}

		.close {
			position: absolute;
			line-height: 18px;

			@if $isHomePage {
				top: 0px;
				right: 0px;
			}

			@else {
				top: 15px;
				right: 15px;
			}

			i {
				font-size: 18px;
				line-height: 18px;
				cursor: pointer;
			}
		}

		.card {
			margin-left: $ticketInformationBanner-accordion-margin-left;
			font-size: 12px;

			.card-header {
				.show {
					color: $ticketInformationBanner-accordion-header-link-color;
					cursor: pointer;

					@include sf_pro_textsemibold;

					&[aria-expanded="true"], &[aria-expanded="true"] ~ .text {
						display: none;
					}
				}
			}

			.card-body {
				.more-info {
					cursor: pointer;

					@if $isHomePage {
						font-weight: normal;
						text-decoration: underline;
						text-decoration-style: dotted;
					}

					@else {
						@include sf_pro_textsemibold;
					}
				}

				.text, .more-info {
					display: initial;

					@include media-breakpoint-down(lg) {
						display: inline-block;
					}
				}

				.benefits {
					margin: 25px 0 0 -#{$ticketInformationBanner-accordion-margin-left};
					text-align: center;

					ul {
						padding: 0;
						margin: 0;
						list-style: none;

						&:after {
							display: block;
							width: 100%;
							clear: both;
							content: " ";
						}

						li {
							display: inline-block;
							line-height: 16px;
							vertical-align: middle;

							@include media-breakpoint-down(lg) {
								width: 25%;
							}

							&:first-child {
								float: left;
							}

							&:last-child {
								float: right;
							}

							div {
								display: inline-block;
								font-size: 12px;
								line-height: 16px;
								text-decoration: underline;
								text-decoration-style: dotted;
								vertical-align: top;
							}

							i {
								font-size: 16px;
								color: $ticketInformationBanner-accordion-benefits-check-icon-color;

								@include media-breakpoint-down(lg) {
									display: block;
								}
							}
						}

						&.other li {
							float: unset;
							width: 49%;
						}
					}
				}
			}
		}
	}

	.ticketInformationBannerOpenedBackground {
		@include media-breakpoint-down(lg) {
			position: fixed !important;
			top: 0px !important;
			left: 0px !important;
			z-index: 25;
			display: none;
			width: 100%;
			max-width: 100%;
			height: 100%;
			margin: 0;
			background: rgba(0, 0, 0, 0.5) !important;
			border: none !important;
			border-radius: unset !important;
		}
	}
}

@mixin ticketInformationBannerPopover() {
	.popover.customPopover.ticketInformationBanner {
		max-width: 200px;
		padding: 10px;
		box-shadow: 0px 0px 31.32px 4.68px $ticketInformationBanner-shadow-color;

		.popover-header {
			padding: 0;
			font-size: 12px;
			color: $ticketInformationBanner-popover-title-color;
			background: unset;
			border-bottom: unset;
		}

		.popover-body {
			padding: 0;
			margin-top: 2px;
			font-size: 12px;
		}
	}
}

@mixin ticketInformationBannerBodyClass() {
	@include media-breakpoint-down(lg) {
		body.ticketInformationBannerOpened {
			overflow: hidden !important;
		}
	}
}

@mixin ticketInformationBannerHomePage() {
	@include headerInformationBanner('.ticketInformationBanner',true);

	.ticketInformationBanner {
		.col-12 {
			padding: 0 13px 0 0;
		}

		.show.showBtn {
			font-size: 12px;
			color: $black;
			cursor: pointer;

			@include sf_pro_textsemibold;
		}
	}
}